import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ExitLoginGuard } from './guards/exit-login.guard';
import { LoginGuard } from './guards/login.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./page/home/home.module').then( m => m.HomePageModule),
    canActivate: [ LoginGuard ]
  },
  {
    path: 'account',
    loadChildren: () => import('./page/account/account.module').then( m => m.AccountPageModule),
    canActivate: [ ExitLoginGuard ]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { ContentModalComponent } from './components/content-modal/content-modal.component';
import { GeneralFormComponent } from './components/general-form/general-form.component';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, TranslateModule],
  declarations: [ContentModalComponent, GeneralFormComponent],
  exports: [ContentModalComponent, GeneralFormComponent],
})
export class SharedModule {}

import { Injectable } from '@angular/core';
// import CryptoJS from 'crypto-js';
// import LZString from 'lz-string';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private keyByCryp = 'materialesCruzPage';

  constructor() {}

  // setItem(key: string, value: unknown, type: 'sesion' | 'local') {
  //   const dataSetear = {
  //     value,
  //   };
  //   const valueToEncrypt = JSON.stringify(dataSetear);
  //   const valueToCompress = CryptoJS.AES.encrypt(
  //     valueToEncrypt,
  //     this.keyByCryp
  //   ).toString();
  //   if (type === 'local') {
  //     localStorage.setItem(
  //       LZString.compress(key),
  //       LZString.compress(valueToCompress)
  //     );
  //   }
  //   if (type === 'sesion') {
  //     sessionStorage.setItem(
  //       LZString.compress(key),
  //       LZString.compress(valueToCompress)
  //     );
  //   }
  // }

  // getItem(key: string, type: 'sesion' | 'local') {
  //   return new Promise<any>((resolve) => {
  //     let dataStorage = '';
  //     if (type === 'local') {
  //       dataStorage =
  //         localStorage.getItem(LZString.compress(key)) === undefined ||
  //         localStorage.getItem(LZString.compress(key)) === null
  //           ? ''
  //           : String(localStorage.getItem(LZString.compress(key)));
  //     }
  //     if (type === 'sesion') {
  //       dataStorage =
  //         sessionStorage.getItem(LZString.compress(key)) === undefined ||
  //         sessionStorage.getItem(LZString.compress(key)) === null
  //           ? ''
  //           : String(sessionStorage.getItem(LZString.compress(key)));
  //     }
  //     const dataStorageDecompress = String(LZString.decompress(dataStorage));
  //     const dataDecryp = CryptoJS.AES.decrypt(
  //       dataStorageDecompress,
  //       this.keyByCryp
  //     ).toString(CryptoJS.enc.Utf8);
  //     let dataJson = null;
  //     if (dataDecryp) {
  //       dataJson = JSON.parse(dataDecryp);
  //     } else {
  //       dataJson = {
  //         value: undefined,
  //       };
  //     }
  //     resolve(dataJson.value);
  //   });
  // }

  // removeItem(key: string, type: 'sesion' | 'local') {
  //   if (type === 'local') {
  //     localStorage.removeItem(LZString.compress(key));
  //   }
  //   if (type === 'sesion') {
  //     sessionStorage.getItem(LZString.compress(key));
  //   }
  // }
}

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import * as SecureLS from 'secure-ls';
import { StorageService } from 'src/app/service/storage.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  ls = new SecureLS({ encodingType: 'aes' });

  constructor(
    private router: Router,
    private storageService: StorageService,
    ) {}

  canActivate() {
    const local = this.ls.get('dUaStEaR');
    // const local = await this.storageService.getItem('dUaStEaR', 'local');
    console.log('*_* loginGuard: ', local);
    if(local === undefined || local === null || local === ''){
      this.router.navigate(['/account/login/empty'], { skipLocationChange: true });
      return false;
    }else{
      return true;
    }
  }
}

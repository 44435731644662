import { Component } from '@angular/core';
import { Router } from '@angular/router';
import * as SecureLS from 'secure-ls';
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  ls = new SecureLS({ encodingType: 'aes' });
  // breakpoints = [
  //   {
  //     screen: 'mobile',
  //     with: 320,
  //   },
  //   {
  //     screen: 'mobileLandscape',
  //     with: 480,
  //   },
  //   {
  //     screen: 'tablet',
  //     with: 768,
  //   },
  //   {
  //     screen: 'tabletLandscape',
  //     with: 992,
  //   },
  //   {
  //     screen: 'desktop',
  //     with: 1200,
  //   },
  //   {
  //     screen: 'desktopLarge',
  //     with: 1500,
  //   },
  //   {
  //     screen: 'desktopWide',
  //     with: 1920,
  //   },
  // ];

  constructor(private storageService: StorageService, private router: Router) {
    console.log('*_* app contrutor');

    document.oncontextmenu = () => false;
    const theme = this.ls.get('theme');
    console.log('*_* theme: ', theme);
    document.body.setAttribute('color-theme', 'light');
    document.body.setAttribute('class', 'light');
    if (!theme) {
      this.ls.set('theme', 'light');
    }
    // const withScreen = window.innerWidth;
    // let screenSize = '';
    // for (const [index, item] of this.breakpoints.entries()) {
    //   if (withScreen < this.breakpoints[1].with) {
    //     screenSize = this.breakpoints[0].screen;
    //   } else if (withScreen >= this.breakpoints[6].with) {
    //     screenSize = this.breakpoints[6].screen;
    //   } else if (
    //     withScreen >= item.with &&
    //     withScreen < this.breakpoints[index + 1].with
    //   ) {
    //     screenSize = item.screen;
    //   }
    // }
    // this.ls.set('currentBreakPoint', screenSize);
    const dUaStEaR = this.ls.get('dUaStEaR');
    if (dUaStEaR) {
      this.router.navigate(['/']);
    }
  }
}

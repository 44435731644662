import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-content-modal',
  templateUrl: './content-modal.component.html',
  styleUrls: ['./content-modal.component.scss'],
})
export class ContentModalComponent implements OnInit {

  @Input() formulario: string;
  @Input() title: string;
  @Input() dataForm: string;
  @Input() buttons: any;

  dataSalida = {
    formValid: false,
    response: null
  };

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {}

  responseFormAddProyecto(salida: any){
    this.dataSalida.formValid = salida.formValid;
    this.dataSalida.response = salida.response;
  }

  dismiss(flag: boolean) {
    if (flag) {
      this.modalController.dismiss({
        data: this.dataSalida
      });
    } else {
      this.modalController.dismiss();
    }
  }

}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-general-form',
  templateUrl: './general-form.component.html',
  styleUrls: ['./general-form.component.scss'],
})
export class GeneralFormComponent {

  @Input() dataForm: any;
  @Output() response = new EventEmitter<any>();

  dataSalida = {
    formValid: false,
    response: null
  };

  constructor() { }

  updateData(form: any) {
    this.send(form.valid);
  }

  send(formValid: boolean) {
    const form = {};
    if (formValid) {
      for (const ll of this.dataForm) {
        form[ll.identificador] = ll.valorDefecto;
      }
    }
    setTimeout(() => {
      this.dataSalida.formValid = formValid;
      if (formValid) {
        this.dataSalida.response = form;
      } else {
        this.dataSalida.response = null;
      }
      this.response.emit(this.dataSalida);
    }, 1);
  }

}

const hostBack = 'https://us-central1-allportal-ee7c5.cloudfunctions.net/app/';

export const environment = {
  production: false,
  useEmulators: false,
  hostBack,
  login: `${hostBack}auth/login`,
  logout: `${hostBack}auth/logout`,
  createUser: `${hostBack}users/new-user`,
  updateUser: `${hostBack}users/update-user`,
  getUsers: `${hostBack}users/get-all`,
};
